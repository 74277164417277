// noinspection JSUnusedGlobalSymbols

import { XMLParser } from 'fast-xml-parser'
import DynamicPath, { getStaticProps } from './index'

export default DynamicPath
export { getStaticProps }

const parser = new XMLParser()
const recursivelyGetSitemap: any = (url: string) => {
  return fetch(url)
    .then(res => res.text())
    .then(xml => {
      // noinspection SpellCheckingInspection
      const { sitemapindex, urlset } = parser.parse(xml)

      if (urlset) {
        return urlset.url.loc ? urlset.url.loc : urlset.url.map((link: any) => link.loc)
      }

      if (sitemapindex) {
        if (sitemapindex.sitemap.loc) {
          return Promise.all([recursivelyGetSitemap(sitemapindex.sitemap.loc)])
        }

        return Promise.all(
          sitemapindex.sitemap.map((sitemap: any) => recursivelyGetSitemap(sitemap.loc))
        )
      }
      return []
    })
}

const getLinks = async () => {
  const URL = `${process.env.NEXT_PUBLIC_WEBFLOW_URL}/sitemap.xml`
  let links = await recursivelyGetSitemap(URL)
  return Array.from(new Set(links.flat(Infinity))) as string[]
}

const getPath = (links: string[]) => {
  return links
    .map(link => {
      const url = new URL(link)
      return url.pathname.replace(`/`, ``).split(`/`)
    })
    .filter(item => item[0]?.length)
    .map(path => {
      return { params: { path: path } }
    })
}

export async function getStaticPaths() {
  const links = await getLinks()
  const paths = getPath(links)

  return {
    paths: paths,
    fallback: 'blocking'
  }
}
